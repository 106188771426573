import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { differenceInYears } from 'date-fns';
import useConsultantBookingsForm from '../hooks/useConsultantBookingForm';
import FormField from '../components/FormField';
import CTAButton from '../components/CTAButton';
import InitialBookingDisclaimer from '../components/InitialBookingDisclaimer';
import CommunicationCard from '../components/CommunicationCard';
import { ReactComponent as Checkmark } from '../static/img/checkmark.svg';

import './ConsultantForm.sass';
import errorActions from '../actions/errorActions';

export default () => {
  const { state } = useLocation();
  const history = useHistory();
  const { log: logError } = errorActions();

  const { detailsForm, toggleTerms, action } = useConsultantBookingsForm(state);

  const [patientUnder16, setPatientUnder16] = useState(false);
  const [patient16or17, setPatient16or17] = useState(false);
  const [patientUnder18, setPatientUnder18] = useState(false);
  const [dobFutureDate, setDobFutureDate] = useState(false);
  const [showAge, setShowAge] = useState(false);
  const [patientAge, setPatientAge] = useState();
  const [patientMakingTheirOwnBooking, setPatientMakingTheirOwnBooking] = useState(true);

  const typeCHospitals = [
    'Nuffield Health Derby Hospital',
    'Nuffield Health Haywards Heath Hospital',
    'Nuffield Health Ipswich Hospital',
    'Nuffield Health Tees Hospital',
  ];
  const typeDHospitals = [
    'Nuffield Health Chichester Hospital',
    'Nuffield Health Hereford (Wye Valley) Hospital',
    'Nuffield Health Highgate Hospital',
    'Nuffield Health Barts Hospital',
    'Nuffield Health Shrewsbury Hospital',
    'Nuffield Health Taunton Hospital',
    'Nuffield Health Wessex Hospital',
    'Nuffield Health Woking Hospital',
  ];
  function checkHospital(hospitalList, hospitalName) {
    return hospitalList.includes(hospitalName);
  }
  const typeCHospitalTrue = checkHospital(typeCHospitals, state.hospital.hospitalName);
  const typeDHospitalTrue = checkHospital(typeDHospitals, state.hospital.hospitalName);

  const day = document.querySelector('#date_of_birth.m--day')?.value;
  const month = document.querySelector('#date_of_birth.m--month')?.value;
  const year = document.querySelector('#date_of_birth.m--year')?.value;
  const dobDate = year && month && day ? new Date(year, month - 1, day) : null;

  useEffect(() => {
    const patientAgeCalc = dobDate
      ? differenceInYears(new Date(state.slotDate), new Date(dobDate))
      : NaN;
    const todayDate = new Date();
    const dobField = document.querySelector('.date-field__outer-wrapper')?.parentElement;
    const errorField = dobField.querySelector('.field-error');
    const CTAButton = document.querySelector('.primary-btn.m--consultant');
    let patientUnder16 = false,
      patient16or17 = false,
      patientUnder18 = false;
    if (typeDHospitalTrue && patientAgeCalc < 18) {
      patientUnder18 = true;
      dobField.classList.add('field--invalid');
      CTAButton.disabled = true;
    } else if (typeCHospitalTrue && patientAgeCalc < 18 && patientAgeCalc >= 16) {
      patient16or17 = true;
      dobField.classList.add('field--invalid');
      CTAButton.disabled = true;
    } else if (typeCHospitalTrue && patientAgeCalc < 16) {
      patientUnder16 = true;
      dobField.classList.add('field--invalid');
      CTAButton.disabled = true;
    } else if (!errorField) {
      setPatientAge(Math.floor(patientAgeCalc));
      dobField.classList.remove('field--invalid');
    }
    if (dobField.classList.contains('field--invalid') && errorField && dobDate > todayDate) {
      setDobFutureDate(true);
      errorField.innerHTML =
        'The date of birth entered cannot be in the future. Please enter a valid date of birth.';
    } else if (dobField.classList.contains('field--invalid') && errorField && dobDate < todayDate) {
      setDobFutureDate(false);
      errorField.innerHTML = 'Please enter a valid date of birth.';
    }
    setPatientUnder16(patientUnder16);
    setPatient16or17(patient16or17);
    setPatientUnder18(patientUnder18);

    if (
      !dobField.classList.contains('field--invalid') &&
      !isNaN(dobDate) &&
      dobDate !== null &&
      !isNaN(patientAge)
    ) {
      setShowAge(true);
    } else {
      setShowAge(false);
    }
  }, [dobDate, state.slotDate, typeCHospitalTrue, typeDHospitalTrue, patientAge]);

  function whoIsThePatient(e) {
    setPatientMakingTheirOwnBooking(e.target.value === 'myself');
  }

  return (
    <>
      <InitialBookingDisclaimer />
      <div className="m--consultant-form">
        <div className="pattern" />
        <div className="header__wrapper mb-40">
          <div className="header">
            <div className="h1">Book consultant</div>
          </div>
        </div>
        <p className="page-title">Who is the consultation for?</p>
        <form className="form-row">
          <fieldset className="form-radio-block">
            <div className="radio-block-options">
              <div className="radio-block-option">
                <input
                  type="radio"
                  id="myself"
                  name="whoIsThePatient"
                  value="myself"
                  onChange={e => {
                    whoIsThePatient(e);
                  }}
                  checked={patientMakingTheirOwnBooking}
                ></input>
                <label htmlFor="myself">Myself</label>
              </div>
              <div className="radio-block-option">
                <input
                  type="radio"
                  id="someoneElse"
                  name="whoIsThePatient"
                  value="someoneElse"
                  onChange={e => {
                    whoIsThePatient(e);
                  }}
                  checked={!patientMakingTheirOwnBooking}
                ></input>
                <label htmlFor="someoneElse">Someone else</label>
              </div>
            </div>
          </fieldset>
        </form>

        {typeCHospitalTrue && (
          <div className="mb-40 under18-disclaimer">
            <div className="h4">Patients under 18</div>
            <p className="pt-10">
              <span className="bold">Under 16: </span>This hospital does not see patients under 16.
              Please{' '}
              <a href="https://www.nuffieldhealth.com/consultants" target="_parent">
                select a different hospital
              </a>
              .
            </p>
            <p className="pt-10">
              <span className="bold">16 & 17-year-olds: </span>We are unable to take online
              bookings, please call <a href="tel:03456029262">0345 602 9262</a> to make your
              appointment.
            </p>
          </div>
        )}
        {typeDHospitalTrue && (
          <div className="mb-40 under18-disclaimer">
            <div className="h4">Patients under 18</div>
            <p className="pt-10">
              This hospital does not take bookings for those below the age of 18. If you are under
              18 or are booking on behalf of someone who is under 18, please{' '}
              <a href="https://www.nuffieldhealth.com/consultants" target="_parent">
                select a different hospital
              </a>
              .
            </p>
          </div>
        )}
        {patientMakingTheirOwnBooking ? (
          <>
            <div className="page-title">Your details</div>
            <div className="mb-30">
              Please fill out your details. We need this information to confirm your appointment.
              <p className="pt-10">* Mandatory field</p>
            </div>
          </>
        ) : (
          <>
            <CommunicationCard
              type="info"
              copy="Please fill out the form below with the patient's details. We need this information to confirm their appointment."
            />
            <div className="page-title">Your details</div>
            <div className="mb-30">
              <p className="pt-10">* Mandatory field</p>
            </div>
          </>
        )}
        <div className="fields mb-30">
          <FormField field={detailsForm.title} errorEmpty="Please select your title." />
        </div>
        <div className="fields">
          <FormField
            field={detailsForm.first_name}
            errorText="Please re-enter the name without accented letters or spaces. For example, Verónica should be Veronica."
            errorEmpty="Please enter your first name."
          />
          <FormField
            field={detailsForm.last_name}
            errorText="Please re-enter the name without accented letters or spaces. For example, Pérez - Fernández should be Perez-Fernandez."
            errorEmpty="Please enter your last name."
          />
          <div className="remaining-fields">
            <FormField
              field={detailsForm.email}
              errorText="Please use the following format: name@domain.com"
              errorEmpty="Please enter your email address."
            />
            <FormField field={detailsForm.addresslookup} errorEmpty="Please enter your address." />
            <div className="gender-field__wrapper">
              <FormField field={detailsForm.gender} errorEmpty="Please select your gender." />
            </div>
            <div>
              <FormField
                field={detailsForm.date_of_birth}
                errorText="Please enter a valid date of birth."
              />
              {showAge && <p className="patient_age">Age on date of appointment: {patientAge}</p>}

              {typeCHospitalTrue && patientUnder16 && !dobFutureDate && (
                <CommunicationCard
                  type="error"
                  icon="true"
                  title="Patient is under 16"
                  copy="Sorry we're unable to book patients under the age of 16 at this hospital. Please <a href='https://www.nuffieldhealth.com/consultants' target='_parent'> select a different hospital</a> or call <a href='tel:03456029262'>0345 602 9262</a>."
                />
              )}
              {typeCHospitalTrue && patient16or17 && !dobFutureDate && (
                <CommunicationCard
                  type="error"
                  icon="true"
                  title="Patient is 16 or 17"
                  copy="We’re unable to take bookings online for patients aged 16 and 17. Please call <a href='tel:03456029262'>0345 602 9262</a> to make your appointment."
                />
              )}
              {typeDHospitalTrue && patientUnder18 && !dobFutureDate && (
                <CommunicationCard
                  type="error"
                  icon="true"
                  title="Patient is under 18"
                  copy="Sorry, we’re unable to see patients under the age of 18 at this hospital. Please <a href='https://www.nuffieldhealth.com/consultants' target='_parent'> select a different hospital</a> or call <a href='tel:03456029262'>0345 602 9262</a>."
                />
              )}
              {!typeCHospitalTrue && !typeDHospitalTrue && (
                <p>
                  If you are under 18 or booking on behalf of someone who is under 18, please
                  call&nbsp;
                  <a href="tel:03456029262">0345 602 9262</a> as we cannot take paediatric bookings
                  online.
                </p>
              )}
            </div>
            <FormField
              field={detailsForm.phone_number}
              errorEmpty="Please enter your phone number."
              errorText="Enter a number starting with 0, the correct amount of digits, and remove any spaces or invalid characters."
            />
            <div>
              <FormField
                field={detailsForm.payment}
                direction="vertical"
                errorText="Please tell us how you'll pay for the consultation."
              />
              {detailsForm.payment.value &&
                (detailsForm.payment.value === 'insurance' ? (
                  <>
                    <div className="mt-20">
                      <FormField
                        field={detailsForm.insurer}
                        errorEmpty="Please select your insurer"
                      />
                    </div>
                    {detailsForm.insurer.value === 'other' && (
                      <div className="mt-20">
                        <FormField
                          field={detailsForm.insurer_name}
                          errorEmpty="Please enter your insurer."
                        />
                      </div>
                    )}
                    <div className="mt-20">
                      <FormField
                        field={detailsForm.policy_number}
                        errorEmpty="Please enter your policy number."
                      />
                    </div>
                    <div className="mt-20">
                      <FormField
                        field={detailsForm.authorization_code}
                        errorText="Please only enter the following: a-z A-Z 0-9 \  / : . - Maximum of 52 characters."
                      />
                    </div>
                    <div className="mt-20">
                      <p>
                        Please note that without an authorisation code you are liable for the full
                        self-pay cost of your initial appointment.
                      </p>
                    </div>
                  </>
                ) : (
                  <p className="cash-or-credit mt-20">
                    We may contact you in advance of the appointment to take payment, otherwise
                    you&apos;ll receive an invoice after your consultation. Please note that if you
                    do not attend your booked appointment you will be liable for the full self-pay
                    cost.
                  </p>
                ))}
            </div>
            <div>
              <FormField
                field={detailsForm.has_gp_referral}
                direction="horizontal"
                errorText="Please tell us whether you have a GP referral or not."
              />
              {detailsForm.has_gp_referral.value && detailsForm.has_gp_referral.value === 'yes' && (
                <p className="mt-20">
                  Please bring your referral letter to your appointment. Before your appointment,
                  we&apos;ll contact you to ask for any relevant documents you have, such as
                  pathology and imaging reports.
                </p>
              )}
              {detailsForm.has_gp_referral.value && (
                <div>
                  <p className="mt-20">
                    If you have any of the following symptoms, please do not continue your online
                    booking and contact your GP or NHS 999/111.
                  </p>
                  <ul className="mt-20">
                    <li>Sudden onset chest pain/heaviness in your chest</li>
                    <li>Heart palpitations with difficulty in breathing</li>
                    <li>Sudden difficulty in breathing</li>
                    <li>Seizure/fitting affecting consciousness and ongoing symptoms</li>
                    <li>
                      Severe allergic reaction with breathing difficulties or swelling of the mouth
                    </li>
                    <li>
                      Sudden onset neurological changes such as weakness, slurring of speech,
                      drowsiness, sudden onset severe headache in the back of the head or neck
                      stiffness with fever
                    </li>
                    <li>Uncontrollable bleeding or a significant injury/accident</li>
                    <li>Pregnancy-related concerns</li>
                    <li>
                      Feeling suicidal – If you are considering harming yourself please contact
                      NHS111 or the Samaritans 116 123
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div>
              <FormField
                field={detailsForm.send_discharge_details_to_gp}
                direction="horizontal"
                errorText="Please tell us whether you wish to have your discharge details to go to your GP."
              />
              {detailsForm.send_discharge_details_to_gp.value === 'yes' && (
                <div className="mt-40">
                  <FormField field={detailsForm.gp_details} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="communication__wrapper mb-40">
          <p className="mb-40">
            To continue to receive communications from Nuffield Health about our exclusive offers,
            products and services, then please tell us how you&apos;d like to be contacted by
            ticking the relevant boxes below:
          </p>
          <div className="mb-40">
            <FormField field={detailsForm.communication_preferences} />
          </div>
          <p className="mb-30">
            On occasion Nuffield Health may contact you with pertinent service information in
            regards to services we provide. Note that you can either amend or withdraw your consent
            at any time.
          </p>
          <p>
            For information about where your personal data may be processed, how it may be processed
            and for details of our Data Protection Officer, please see our Privacy Policy.
          </p>
        </div>

        <div className="custom-checkbox__wrapper">
          <input
            className="custom-checkbox__value"
            id="toc"
            type="checkbox"
            onChange={toggleTerms}
          />
          <label className="custom-checkbox__label" htmlFor="toc">
            <span className="custom-checkbox__faux">
              <Checkmark />
            </span>
            <span>I agree to the </span>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.nuffieldhealth.com/terms/nuffield-health-website-terms-and-conditions"
            >
              terms and conditions
            </a>
            <span> and </span>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.nuffieldhealth.com/privacy"
            >
              privacy policy
            </a>
          </label>
        </div>
        <div className="ctas__wrapper mt-40">
          <CTAButton
            action={action}
            onAction={result => {
              if (result.statusCode === 'true') {
                history.replace(`/consultants/${state.consultant.gmcCode}/booked/`, {
                  ...state,
                  gpReferral: detailsForm.has_gp_referral.value === 'yes',
                  consultationFor: patientMakingTheirOwnBooking ? 'myself' : 'someone else',
                });
              } else {
                logError({
                  error: 'CUSTOM',
                  text: 'Sorry but we are unable to process your booking online. Please call 0345 602 9262 to complete your booking.',
                });
              }
            }}
          />
          <CTAButton
            className="back-btn"
            action={{
              call: async () => history.goBack(),
              name: 'Back',
              type: 'BACK',
            }}
          />
        </div>
      </div>
    </>
  );
};
