export const INSURERS = [
  ['Aviva', 'Aviva'],
  ['AXA PPP Healthcare', 'AXA PPP Healthcare'],
  ['Bupa', 'Bupa'],
  ['Bupa Global', 'Bupa Global'],
  ['Cigna International', 'Cigna International'],
  ['Vitality Health', 'Vitality Health'],
  ['WPA', 'WPA'],
  ['Alliance Surgical', 'Alliance Surgical'],
  ['Allianz Worldwide Care Limited', 'Allianz Worldwide Care Limited'],
  ['Assist Card Espana', 'Assist Card Espana'],
  ['The Exeter', 'The Exeter'],
  ['Healix Health Services Limited', 'Healix Health Services Limited'],
  ['Now Healthcare International', 'Now Healthcare International'],
  ['Health Partners Europe Ltd', 'Health Partners Europe Ltd'],
  ['National Police Healthcare Scheme', 'National Police Healthcare Scheme'],
  ['Police Mutual', 'Police Mutual'],
  ['Sovereign Risk Management Ltd', 'Sovereign Risk Management Ltd'],
  ['TrusteDoctor', 'TrusteDoctor'],
  ['First Health Trust Ltd', 'First Health Trust Ltd'],
  ['General & Medical', 'General & Medical'],
  ['H3 Insurance', 'H3 Insurance'],
  ['HealthCare International Global Network Ltd', 'HealthCare International Global Network Ltd'],
  ['Healthcare RM', 'Healthcare RM'],
  ['Healthnow AIG', 'Healthnow AIG'],
  ['Hospital and Medical Care Association (HMCA)', 'Hospital and Medical Care Association (HMCA)'],
  ['HTH Worldwide / GeoBlue', 'HTH Worldwide / GeoBlue'],
  ['International Medical Group (IMG)', 'International Medical Group (IMG)'],
  ['International SOS', 'International SOS'],
  ['International Tricare', 'International Tricare'],
  ['Multiplex', 'Multiplex'],
  ['Preferred Health Care Ltd', 'Preferred Health Care Ltd'],
  ['United Healthcare Global Insurance', 'United Healthcare Global Insurance'],
];
